import { SelectionModel } from "@angular/cdk/collections";
import { CurrencyPipe, DatePipe, NgTemplateOutlet } from "@angular/common";
import {
  Directive,
  Signal,
  TemplateRef,
  Type,
  computed,
  contentChild,
  effect,
  input,
  model,
  output,
  signal,
  viewChild,
} from "@angular/core";
import { MatCheckboxModule } from "@angular/material/checkbox";
import {
  MatPaginator,
  MatPaginatorModule,
  PageEvent,
} from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSortModule, Sort, SortDirection } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { ChipUi } from "@app/pages/customer/+ui/chip/chip.ui";
import { ContentVisibleHoverUi } from "@app/pages/job/+ui/content-visible-hover.ui";
import { TAsyncState } from "@app/pages/task/+data-access";
import { EmptyStateComponent } from "@app/shared/components/empty-state/empty-state.component";
import { InlineSVGModule } from "ng-inline-svg-2";
import { Observable, take } from "rxjs";
import { ExtractStringEnum } from "./typing.utils";
import { dedupItems } from "./array.utils";

export const ROAM_TABLE_COL_DEF_BASE = {
  Index: "index",
  Selections: "selections",
} as const;

export type RoamTableOptions = {
  pageIndex: number;
  pageSize: number;
  sortActive: string;
  sortDirection: SortDirection;
} & Record<string, any>;

export type RoamTableRowChangeEvent<Model = any> = {
  rowIndex: number;
  rowData: Model;
  changes: Partial<Model>;
};

export type RoamTableRowClickEvent<Model = any> = {
  rowIndex: number;
  rowData: Model;
};

export class RoamTableMultiSelectModel<ModelKey = string | number> {
  protected selectionModel = new SelectionModel<ModelKey>(true, []);
  readonly keys = signal<ModelKey[]>([]);

  readonly connect = (
    keys: ModelKey[] | Signal<ModelKey[]> | Observable<ModelKey[]>
  ) => {
    if (keys instanceof Observable) {
      keys.pipe(take(1)).subscribe(x => this.keys.set(x));
      return;
    }

    if (Array.isArray(keys)) {
      this.keys.set(keys);
      return;
    }

    this.keys.set(keys());
  };

  get isAllSelected() {
    return this.keys().every(key => this.selectionModel.isSelected(key));
  }

  get hasSomeSelected() {
    return this.selectionModel.hasValue() && !this.isAllSelected;
  }

  isSelected = (key: ModelKey) => {
    return this.selectionModel.isSelected(key);
  };

  // TODO: update keys instead of model for more reactivity supports
  reset = () => {
    this.keys.set([]);
    this.selectionModel.clear();
  };

  selectOne = (key: ModelKey) => {
    return this.selectionModel.select(key);
  };

  selectMany = (keys: ModelKey[]) => {
    return this.selectionModel.select(...keys);
  };

  setMany = (keys: ModelKey[]) => {
    this.selectionModel.clear();
    return this.selectionModel.select(...keys);
  };

  removeMany = (keys: ModelKey[]) => {
    return this.selectionModel.deselect(...keys);
  };

  toggleOne = (key: ModelKey) => {
    return this.selectionModel.toggle(key);
  };

  toggleAll = () => {
    if (this.isAllSelected) {
      return this.selectionModel.clear();
    } else {
      return this.selectionModel.select(...this.keys());
    }
  };
}

@Directive({
  standalone: true,
  selector: "[roamNoPropagation]",
  host: {
    "(click)": "stopProgation($event)",
  },
})
export class RoamNoPropagationDirective {
  stopProgation(e: Event): void {
    e.stopPropagation();
  }
}

export const ROAM_TABLE_BASE_MODULES: Type<any>[] = [
  DatePipe,
  CurrencyPipe,
  MatCheckboxModule,
  MatTableModule,
  NgTemplateOutlet,
  MatProgressBarModule,
  MatPaginatorModule,
  MatSortModule,
  InlineSVGModule,
  EmptyStateComponent,
  ContentVisibleHoverUi,
  ChipUi,
  RoamNoPropagationDirective,
];

@Directive({
  host: { class: "roam-mdc-table-wrapper" },
})
export abstract class RoamTableAccessor<
  Model extends Record<string, any>,
  ColDef extends ExtractStringEnum<typeof ROAM_TABLE_COL_DEF_BASE>,
> {
  readonly selections = new RoamTableMultiSelectModel<string>();
  readonly columnDefs = input.required<ColDef[]>();
  readonly asyncProgress = input<TAsyncState>("idle");
  readonly dataSource = input<Model[]>([]);
  readonly total = input(0);
  readonly showPagination = input(true);
  readonly isLoading = computed(() => this.asyncProgress() === "loading");
  readonly isSubmitting = computed(() => this.asyncProgress() === "submitting");
  readonly isEmpty = computed(() => {
    return this.asyncProgress() === "loaded" && !this.total();
  });
  readonly options = model<RoamTableOptions>({
    pageSize: 10,
    pageIndex: 1,
    sortActive: "",
    sortDirection: "asc",
  });
  readonly paginator = viewChild(MatPaginator);
  readonly emptyView = contentChild("emptyTemplate", { read: TemplateRef });
  readonly rowClick = output<RoamTableRowClickEvent<Model>>();
  readonly rowChange = output<RoamTableRowChangeEvent<Model>>();

  get paginatorPageIndex() {
    return this.paginator()?.pageIndex ?? this.options().pageIndex - 1;
  }

  get paginatorPageSize() {
    return this.paginator()?.pageSize ?? this.options().pageSize;
  }

  protected onRowClick(index: number, data: Model): void {
    if (this.hasSelections()) {
      this.toggleOne(data[this.modelKey]);
    } else {
      this.rowClick.emit({ rowIndex: index, rowData: data });
    }
  }

  onSortChange(e: Sort): void {
    this.options.update(opts => ({
      ...opts,
      sortActive: e.active,
      sortDirection: e.direction,
    }));
  }

  onPage(e: PageEvent): void {
    this.options.update(opts => ({
      ...opts,
      pageIndex: e.pageIndex + 1,
      pageSize: e.pageSize,
    }));
  }

  get modelKey() {
    return this.config.modelKey;
  }

  readonly allIds = computed(() =>
    this.dataSource().map(x => x[this.modelKey])
  );

  readonly allSelected = computed(() =>
    this.allIds().every(x => this.selections.keys().includes(x))
  );

  isSelected = (id: string) => {
    return this.selections.keys().includes(id);
  };

  toggleOne = (id: string): void => {
    this.selections.keys.update(list => {
      if (list.includes(id)) {
        return list.filter(x => x !== id);
      } else {
        return dedupItems([...list, id]);
      }
    });
  };

  selectAll = () => {
    this.selections.keys.set(this.allIds());
  };

  toggleAll = () => {
    if (this.allSelected()) {
      this.selections.keys.set([]);
    } else {
      this.selectAll();
    }
  };

  readonly hasSelections = computed(() => {
    return !!this.selections.keys().length;
  });

  constructor(public config: { modelKey: keyof Model }) {
    // effect(
    //   () => {
    //     const data = this.dataSource();
    //     const columns = this.columnDefs();
    //     if (~columns.findIndex(x => x === "selections") && data.length) {
    //       const selected = data.map(x => x[config.modelKey]);
    //       this.selections.connect(selected);
    //     }
    //   },
    //   { allowSignalWrites: true }
    // );
  }
}
